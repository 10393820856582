@import url('https://fonts.googleapis.com/css?family=Open+Sans|Viga');// $primary: #a51616;
$primary: #a60008; /* MAIN COLOR */
$secondary: #720005; /* SECONDARY COLOR */
$tertiary: #993D42;
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

.flash {
	display:none;
}

nav {
	z-index: 1000;
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 80px;
		display: flex;
		align-items: center;


		@media (max-width: 767px) {
		    height: 38px;
		    display: inline-block;
		}

	    &:hover, &:focus {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }
	}
}

.navbar-right {
	margin-top: 0px;
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 1.5em 3.5em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $secondary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1280px) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

p {
	font-family: 'Open Sans', sans-serif;
	font-size: 20px;
}
h1,h2,h3 {
	font-family: 'Viga', sans-serif;
}
.introTxt {
	font-size: 20px;
	line-height: 1.4;
}
.btn.btn-large {
	@include btn1;
	@media (max-width: 767px) {
		margin-top: 15px;
	}
	@media (min-width: 1024px) {
		margin-top: 15px;
	}
}

.bg {
	background: $tertiary;
	padding: 50px 0;
	h1 {
		color: white;
	}
}

.logo {
	max-width: 300px;
	width: 100%;
	@media (max-width: 767px) {
		max-width: 200px;
	}
	@media (max-width: 500px) {
		max-width: 150px;
		margin-top: 10px;
	}
}

.nav.navbar-nav {
	margin-top: 13px;
}

.arrow {
	display: block;
	margin: 0 auto;
	max-width: 250px;
	width: 100%;
}

.box {
	background: rgba($secondary,0.6);
	border-radius: 20px;
	padding: 20px;
	color: white;
}
.bg2 {
	padding: 175px 0;
	background: url(../img/banner3.jpg) no-repeat;
	background-size: cover;
	@media (max-width: 991px) {
		padding: 75px 0;
	}
	@media (max-width: 600px) {
		background-position: 40% 50%;
		padding: 50px 0;
	}
	@media (max-width: 1024px) and (orientation: landscape) {
		background-position: 50% 50%;
	}
}

.couple {
	height: 400px;
}

.couple1 {
	background: url(../img/couple1.jpg) no-repeat;
	background-position: 50% 30%;
	background-size: cover;
}
.couple2 {
	background: url(../img/couple2.jpg) no-repeat;
	background-position: 50% 30%;
	background-size: cover;
}
.couple3 {
	background: url(../img/couple3.jpg) no-repeat;
	background-position: 50% 30%;
	background-size: cover;
}
.overlay {
	position: relative;
	overflow: hidden;
	img {
	width: 100%;
}
	h1, p {
		position: relative;
	}
	h1 {
	top: 40%;
	color: white;
	opacity: 0;
	 transition: opacity 0.5s ease;;
	 z-index: 2;
	 @media (max-width: 1199px) {
	 	font-size: 25px;
	 }
}
&:after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
}
&:hover {
	h1, p {
		opacity: 1
	}
	&:after {
		opacity: 1;
		background: rgba(0, 0, 0, 0.6);
	}
}
}

@media (max-width: 1280px) {
	.overlay:after {
		opacity: 1;
		background: rgba(0, 0, 0, 0.6);
	}
	.overlay h1 {
		opacity: 1;
	}
}

.icon {
	h1 {
		@media (max-width: 991px) {
			font-size: 18px;
		}
	}
	@media (max-width: 500px) {
	width: 100%;
	}
}

#owl-demo img {
	width: 100%;
	position: relative;
}

#owl-demo .caption {
	position: absolute;
	display: block;
	top: 40%;
	width: 800px;
	margin-left: -400px;
	left: 50%;
	@media (max-width: 991px) {
		width: 500px;
		margin-left: -250px;
	}
	@media (max-width: 700px) {
		top: 30%;
	}
	@media (max-width: 500px) {
		width: 300px;
		margin-left: -150px;
	}
	@media (max-width: 330px) {
		width: 200px;
		margin-left: -100px;
	}
	h1 {
		font-size: 6rem;
		text-shadow: 1px 1px 10px white;
		color: #90191c;
		@media (max-width: 991px) {
			font-size: 2.5em;
		}
		@media (max-width: 500px) {
			font-size: 1.8em;			
		}
		@media (max-width: 330px) {
			font-size: 1.2em;
		}
	}
}

.top-pad li, .top-pad {
	font-family: 'Open Sans', sans-serif;
}
